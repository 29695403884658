
.ai-talk-gn-normal, .ai-talk-gn-active{
  position:relative;
  margin: 5% auto;
  border-radius:50%;
  width:9em;
  height:9em;
}

.ai-talk-gn-normal:before, .ai-talk-gn-normal:after, .ai-talk-gn-active:before, .ai-talk-gn-active:after, .ai-talk-mc:before, .ai-talk-mc:after{
  content:'';
  position:absolute;
  background-color: #fff;
}

.ai-talk-gn-normal:after, .ai-talk-gn-active:after{
  top:30%; left:43%;
  height:15%; width:14%;
  border-top-left-radius:50%;
  border-top-right-radius:50%;
}

.ai-talk-gn-normal:before, .ai-talk-gn-active:before{
  top:40%; left:43%;
  height:15%; width:14%;
  border-bottom-left-radius:50%;
  border-bottom-right-radius:50%;
}

.ai-talk-mc{
  position:absolute;
  top:50%; left:37%;
  height:24%;
  width:26.5%;
  overflow:hidden;
}

.ai-talk-mc:before{
  bottom:50%;
  width:100%; height:100%;
  box-sizing:border-box;
  border-radius:50%;
  background:none;
}

.ai-talk-mc:after{
  top:50%; left:40%;
  width:20%; height:25%;
}

.ai-talk-msgs-pc, .ai-talk-msgs-chat-mobile, .ai-talk-msgs-dictation-mobile, .ai-talk-msgs-text-only{
  overflow: scroll;
}

.ai-talk-container-pc, .ai-talk-container-mobile{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  user-select:none;
  border-radius: 3%;
}

.ai-talk-settings-btn, .ai-talk-settings-menu, .ai-talk-settings-menu2{
  display: inline-block;
  float: right;
}

.ai-talk-settings-menu{
  margin-right: 150px;
  margin-top: 25px;
}

.ai-talk-settings-menu2{
  margin-right: 165px;
  margin-top: 25px;
}

.ai-talk-container-pc-input{
  margin-bottom: 0.15rem!important;
}

.ai-talk-container-pc{
  min-height: 47vh;
  max-height: 47vh;
  min-width: 30vw;
}

.ai-talk-container-mobile-input{
  margin-bottom: 0.05rem!important;
}

.ai-talk-container-mobile{
  min-height: 27vh;
  max-height: 27vh;
  min-width: 30vw;
}

.ai-talk-msgs-pc, .ai-talk-msgs-chat-mobile, .ai-talk-msgs-dictation-mobile, .ai-talk-msgs-text-only{
  margin-bottom: 0px;
}

.ai-talk-msgs-card{
  margin-bottom: 0.2rem;
  padding: 0.5rem;
}

.ai-talk-msgs-pc {
  min-height: 89vh;
  max-height: 89vh;
  min-width: 55vw;
}

.ai-talk-msgs-chat-mobile {
  min-height: 52vh;
  max-height: 52vh;
}

.ai-talk-msgs-dictation-mobile {
  min-height: 65vh;
  max-height: 65vh;
}

.ai-talk-msgs-text-only {
  min-height: 50vh;
  max-height: 96vh;
  min-width: 90vw;
}

.ai-talk-spinner {
  width: 10vh;
  height: 10vh;
}

.ai-talk-container-pc, .ai-talk-container-mobile{
  background-color: #555555;
}

.ai-talk-gn-normal{
  background-color: #787878;
}

.ai-talk-gn-active{
  background-color: #60B460;
}

.ai-talk-mc:before{
  border: 0.5em solid #fff;
}
